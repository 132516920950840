export const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export const STRICT_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b)(?!\.)([-\/\p{Letter}\p{Mark}0-9()@:%;_\+.~#?&//=]*)$/u;

export const NOT_STRICT_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b)(?!\.)([-\/\p{Letter}\p{Mark}0-9()@:%;_\+.~#?&//=]*)$/u; // http(s) and www. are optional

export const HTTP_PROTOCOL_REGEX = /^https?:\/\/.+/i;

export const isEmail = (email = '') => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const referralsBlackList = (email = '') => {
  const regex =
    /^([a-zA-Z0-9_.+-]+@(?!protonmail.*)(?!zohomail.*)(?!quickmail.com)(?!inbox.com)(?!gmail.com)(?!icloud.com)(?!gmial.com)(?!gmali.com)(?!yahoo.com)(?!hotmail.com)(?!aol.com)(?!hotmail.co.uk)(?!gamil.com)(?!gmanil.com)(?!gmali.com)(?!hotmail.fr)(?!msn.com)(?!yahoo.fr)(?!wanadoo.fr)(?!orange.fr)(?!comcast.net)(?!yahoo.co.uk)(?!yahoo.com.br)(?!yahoo.co.in)(?!live.com)(?!rediffmail.com)(?!free.fr)(?!gmx.de)(?!web.de)(?!yandex.ru)(?!ymail.com)(?!libero.it)(?!outlook.com)(?!uol.com.br)(?!bol.com.br)(?!mail.ru)(?!cox.net)(?!hotmail.it)(?!sbcglobal.net)(?!sfr.fr)(?!live.fr)(?!verizon.net)(?!live.co.uk)(?!googlemail.com)(?!yahoo.es)(?!ig.com.br)(?!live.nl)(?!bigpond.com)(?!terra.com.br)(?!yahoo.it)(?!neuf.fr)(?!yahoo.de)(?!alice.it)(?!rocketmail.com)(?!att.net)(?!laposte.net)(?!facebook.com)(?!bellsouth.net)(?!yahoo.in)(?!hotmail.es)(?!charter.net)(?!yahoo.ca)(?!yahoo.com.au)(?!rambler.ru)(?!hotmail.de)(?!tiscali.it)(?!shaw.ca)(?!yahoo.co.jp)(?!sky.com)(?!earthlink.net)(?!optonline.net)(?!freenet.de)(?!t-online.de)(?!aliceadsl.fr)(?!virgilio.it)(?!home.nl)(?!qq.com)(?!telenet.be)(?!me.com)(?!yahoo.com.ar)(?!tiscali.co.uk)(?!yahoo.com.mx)(?!voila.fr)(?!gmx.net)(?!mail.com)(?!planet.nl)(?!tin.it)(?!live.it)(?!ntlworld.com)(?!arcor.de)(?!yahoo.co.id)(?!frontiernet.net)(?!hetnet.nl)(?!live.com.au)(?!yahoo.com.sg)(?!zonnet.nl)(?!club-internet.fr)(?!juno.com)(?!optusnet.com.au)(?!blueyonder.co.uk)(?!bluewin.ch)(?!skynet.be)(?!sympatico.ca)(?!windstream.net)(?!mac.com)(?!centurytel.net)(?!chello.nl)(?!live.ca)(?!aim.com)(?!bigpond.net.au)([\w-]+\.)+[\w-]{2,9})?$/;
  return regex.test(email);
};

export const isNonPersonalEmail = (email = '') => {
  const regex =
    /^([a-zA-Z0-9_.+-]+@(?!gmail.com*)(?!qq.com*)(?!hotmail.com*)(?!yahoo.com*)(?!outlook.com*)(?!icloud.com*)([\w-]+\.)+[\w-]{2,9})?$/;
  return regex.test(email);
};

export const IS_EU_VAT_NUMBER_REGEX =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const isOTP = (otp = '') => {
  let regex = /^\d{6}$/;
  return regex.test(otp);
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isValidName = (/*name = "", country*/) => {
  return true;
  // const COUNTRY_SPECIFIC_CHARS = {
  //   RU: "\u0400-\u04FF",
  //   JP: "\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf",
  //   CN: "\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD",
  // };

  // const chars = COUNTRY_SPECIFIC_CHARS[country] || "";

  // const regex = new RegExp(`^[A-Za-z0-9 ${chars}]*$`, "gm");

  // return regex.test(name);
};

export const WHITESPACE_OR_EMPTY_ENTRY_REGEX = /^$|^(.*)?\S+(.*)?$/;

export const IS_UUID = /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/g;
export const IS_ALPHA_NUMERIC = /\b[a-zA-Z]*\d+[a-zA-Z\d]*\b/g;
