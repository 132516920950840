import { switchLanguage } from '@/utils/localization/switchLanguage';

export interface Translation {
  [key: string]: string | number | boolean | Translation | Translation[];
}

function removeLangQueryParamIfExists() {
  const url = new URL(window.location.href);
  const lang = url.searchParams.get('lang');

  if (lang) {
    url.searchParams.delete('lang');
    window.history.replaceState({}, '', url.toString());
    switchLanguage(lang, false);
  }
}

export const loadTranslations = (modules: Record<string, unknown>) => {
  removeLangQueryParamIfExists();

  // Define static glob paths for all languages you support
  const translations: { [lang: string]: Translation } = {};

  Object.entries(modules).forEach(([path, module]) => {
    const match = path.match(/^\.\/(.*?)\/(.*)\.json$/);
    if (match) {
      const [, , filePath] = match;
      const key = filePath.replace(/\//g, '.');
      translations[key] = (module as { default: Translation }).default;
    }
  });

  return translations;
};

export const en = loadTranslations(import.meta.glob('./en/**/*.json', { eager: true }));
