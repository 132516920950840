import { registerPlugin } from '@capacitor/core';
import * as Sentry from '@sentry/react';

export interface AppLaunchStatusPlugin {
  registerKeys(options: { keys: Array<{ key: string; value?: string }> }): Promise<void>;
  getStatus(options: { key: string }): Promise<{ key: string; value: string }>;
  setStatus(options: { key: string; value: string }): Promise<{ key: string; value: string }>;
}

let AppLaunchStatus: AppLaunchStatusPlugin | null = null;

try {
  AppLaunchStatus = registerPlugin<AppLaunchStatusPlugin>('AppLaunchStatus');
} catch (error) {
  console.error('Failed to register AppLaunchStatus plugin:', error);
  Sentry.captureException(error);
}

export { AppLaunchStatus };
