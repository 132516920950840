export const STAGE = window.STAGE;
export const SITE_IS_DOWN = false && !+localStorage.getItem('maintenance_override'); // Switching this to true will display the "Down for maintenance" screen

export const API_URL = window.API_URL || import.meta.env.VITE_TEST_API_URL;

export const IS_COOKIE_TOKEN = window.IS_COOKIE_TOKEN;

export const PATCHES = {};

export const VERSION_KEY_PREFIX = 'APP_VERSION_';

export const RELEASE_NAME = `${import.meta.env.VITE_CI_COMMIT_SHA}${
  import.meta.env.VITE_MOBILE_BUILD ? '-mobile' : ''
}`;
