import instance from '@/utils/api/instance';
import dataLayer from '../dataLayer';
import { EventActions, EventCategories, EventProducts } from '../analytics/constants';

const patchPreferredUserLanguage = async (languageCode: string) => {
  try {
    await instance.patch(`/profiles/me/language`, { language: languageCode });
  } catch {
    console.error('failed to save user preferred language');
  }
};

export const switchLanguage = async (language: string, needsUpdate: boolean = true) => {
  dataLayer.push({
    event_category: EventCategories.Language,
    event_product: EventProducts.Localization,
    event_action: EventActions.LanguageSwitch,
    cta_name: language,
  });
  if (needsUpdate) {
    await patchPreferredUserLanguage(language);
  }
  localStorage.setItem('language', language);
  window.location.reload();
};
