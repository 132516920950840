import axios from 'axios';

import { API_URL } from '@/constants/main';

const maintenanceOverride = localStorage.getItem('maintenance_override');
const language = localStorage.getItem('language') || 'en';

export const axiosHeaders = Object.assign(
  {
    'x-app-host': window.location.host,
    'x-auth-token': '',
    'x-api-version': '2',
    'x-locale': language,
  },
  maintenanceOverride && +maintenanceOverride ? { 'x-maintenance': 'ignore' } : {}
);

const instance = axios.create({
  baseURL: API_URL,
  headers: axiosHeaders,
});

interface ISWRData {
  mutate?: (key: string, data: any, state?: any) => void;
}
let swrData: ISWRData = {};
export const setSWRData = (data: ISWRData) => ((swrData = data), undefined);
export const getSWRData = () => swrData;

export default instance;
