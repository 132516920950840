export const DYNAMIC_RENDER_DEBUG_KEY = 'dynamicRenderDebug';

export const VISIBLE_COLOR = '#0e550e';
export const HIDDEN_COLOR = '#822b2b';

export const colorSelect = (isVisible: boolean) => (isVisible ? VISIBLE_COLOR : HIDDEN_COLOR);

export const borderStyle = `1px solid ${colorSelect(false)}`;

export const accordionStyle = (isVisible: boolean) => {
  const color = colorSelect(isVisible);
  return {
    '.MuiAccordionSummary-content': {
      '.MuiButtonBase-root': {
        span: {
          textTransform: 'none',
          fontSize: 10,
          color,
        },
        hr: {
          borderColor: color,
        },
      },
    },
  };
};
