import { isNative } from './crossPlatform';
import { App } from '@capacitor/app';
import { setTag } from '@sentry/react';

const APP_INFO_KEYS = {
  version: 'NATIVE_APP_VERSION',
  buildNumber: 'NATIVE_APP_BUILD_NUMBER',
  otaBuildId: 'NATIVE_OTA_BUILD_ID',
};

const setItem = (key: string, value: string): void => {
  if (!isNative()) return;
  localStorage.setItem(key, value);
};

const getItem = (key: string): string | undefined => (isNative() ? localStorage.getItem(key) ?? undefined : undefined);

export const saveAppVersionInfo = async (): Promise<void> => {
  if (!isNative()) return;
  const { version, build: buildNumber } = await App.getInfo();
  setTag('app-version', version);
  setItem(APP_INFO_KEYS.version, version);
  setItem(APP_INFO_KEYS.buildNumber, buildNumber);
};

export const getAppVersion = (): string | undefined => getItem(APP_INFO_KEYS.version);

export const getAppBuildNumber = (): string | undefined => getItem(APP_INFO_KEYS.buildNumber);

export const saveOTABuildId = (buildId: string): void => setItem(APP_INFO_KEYS.otaBuildId, buildId);

export const getOTABuildId = (): string | undefined => getItem(APP_INFO_KEYS.otaBuildId);
