// @ts-ignore
import { isNative } from '@/utils/crossPlatform';
import { getAppVersion } from './nativeAppInfo';

window.dataLayer = window.dataLayer || [];
function gtag(...args: any) {
  // @ts-ignore
  window.dataLayer.push(...args);
}
gtag('consent', 'default', {
  ad_storage: 'denied',
  analytics_storage: 'denied',
  wait_for_update: 500,
});
gtag('set', 'ads_data_redaction', true);
// @ts-ignore
window.gtag = gtag;

export type PushEvent = {
  event?: 'interaction' | 'page_view';
  event_area?: EVENT_AREA;
  event_category?: string;
  event_product: string;
  event_action: string;
};

export enum EVENT_AREA {
  APP = 'App',
  NATIVE = 'Native',
  EMAIL = 'Email',
}
export type PushEventExtraParams<T = undefined> = { [key: string]: string | number | undefined | T };
export type PushEventObject<T = undefined> = PushEvent & PushEventExtraParams<T>;

const defaultProps: Record<string, string | number> = {};
const app_version = getAppVersion();

export const getDefaultEventArea = () => {
  return isNative() ? EVENT_AREA.NATIVE : EVENT_AREA.APP;
};

const set = (object: { [key: string]: string | number }) => {
  Object.assign(defaultProps, object);
};

const getDefaultProps = () => {
  return defaultProps;
};

const push = <T>(object: PushEventObject<T>) => {
  try {
    // @ts-ignore
    window.dataLayer.push({
      /**
       *  There is only one other event: 'page_view' that overrides the line below
       */
      event: 'interaction',
      event_area: getDefaultEventArea(),
      ...object,
      ...defaultProps,
      ...(app_version ? { app_version } : {}),
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  getDefaultProps,
  set,
  push,
};
