import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';

const PREFIX = 'localStorage.';

const localToPreferenceKey = (key: string) => `${PREFIX}${key}`;
const preferenceToLocalKey = (key: string) => key.replace(PREFIX, '');
const getPreferenceKeys = async () => {
  const preferences = (await Preferences.keys()).keys;
  return preferences.filter((key) => key.startsWith(PREFIX)).map(preferenceToLocalKey);
};
const getPreference = async (key: string) => {
  return (await Preferences.get({ key: localToPreferenceKey(key) })).value;
};
const setPreference = async (key: string, value: string) => {
  return Preferences.set({ key: localToPreferenceKey(key), value });
};
const removePreference = async (key: string) => {
  return Preferences.remove({ key: localToPreferenceKey(key) });
};

export const persistLocalStorageHandler = async () => {
  const saveLocalStorage = async () => {
    const localStorageKeys = Object.keys(localStorage).filter((key) => !key.startsWith('CapacitorStorage.'));
    const preferenceKeys = await getPreferenceKeys();
    for (const key of preferenceKeys) {
      if (!localStorageKeys.includes(key)) {
        await removePreference(key);
      }
    }
    for (const key of localStorageKeys) {
      await setPreference(key, localStorage.getItem(key) || '');
    }
  };

  const loadLocalStorage = async () => {
    const preferenceKeys = await getPreferenceKeys();
    for (const key of preferenceKeys) {
      localStorage.setItem(key, (await getPreference(key)) || '');
    }
  };

  App.addListener('pause', saveLocalStorage);
  // TODO: Check if localStorage can be cleared when the app is in the background? If so, uncomment this line
  // App.addListener('resume', loadLocalStorage);
  await loadLocalStorage();
};
